import axios from 'axios'
import store from '@/store'
import router from '@/router'
# import Vue from "vue"

# Vue.use(router)

class ApiClient
    constructor: () ->
        this.axiosBase = axios.create(
            baseURL: process.env.VUE_APP_API_BASE_URL
        )

    initToken: ()->
        result = @axiosBase.get(
            '/posts',
        ).then(
            (res) =>
                return res
        ).catch(
            (err) =>
                return err
        )
        return result

    get: (endPoint, addHeader = true, successCallback = null, failCallback = null) ->
        if document.querySelector('#connections')
            document.querySelector('#connections').style.display = 'block'
        header = {}
        if addHeader
            header =
                headers:
                    token: store.getters.getToken
                    contentType: 'application/json'
        result = this.axiosBase.get(
            endPoint,
            header,
        ).then(
            (res) =>
                if document.querySelector('#connections')
                    document.querySelector('#connections').style.display = 'none'
                if (!this._resultParse(res))
                    return
                if (successCallback)
                    successCallback(res)
                return res.data.data
        ).catch(
            (err) =>
                if document.querySelector('#connections')
                    document.querySelector('#connections').style.display = 'none'
                if (failCallback)
                    failCallback(err)
                # router.push('/')
                return err
        )
        return result

    post: (endPoint, param, addHeader = true)->
        if document.querySelector('#connections')
            document.querySelector('#connections').style.display = 'block'
        store.dispatch('setErrors', '')
        header = {}
        if (addHeader)
            header =
                headers:
                    token: store.getters.getToken
                    contentType: 'application/json'

        params = new URLSearchParams()
        json = JSON.stringify(param)
        params.append('data', json)
        result = this.axiosBase.post(
            endPoint,
            params,
            header,
        ).then(
            (res) =>
                if document.querySelector('#connections')
                    document.querySelector('#connections').style.display = 'none'
                if (!this._resultParse(res))
                    return
                store.dispatch('setErrors', res.data.data.errors)
                return res.data.data
        ).catch(
            (err) =>
                if document.querySelector('#connections')
                    document.querySelector('#connections').style.display = 'none'
                router.push('/')
                return err
        );
        return result

    _resultParse: (res) ->
        if(res.data.data.state == 444 || res.data.data.state == 500)
            # router.push('/')
            return false
        store.dispatch('token', res.data.data._code)
        return true

    confirmGetFile: (endPoint) ->
        if document.querySelector('#connections')
            document.querySelector('#connections').style.display = 'block'
        header = {}
        header =
            headers:
                token: store.getters.getToken
                contentType: 'application/json'
        result = axios.get(
            endPoint,
            header,
        ).then(
            (res) =>
                if document.querySelector('#connections')
                    document.querySelector('#connections').style.display = 'none'
                return true
        ).catch(
            (err) =>
                if document.querySelector('#connections')
                    document.querySelector('#connections').style.display = 'none'
                alert('File not found')
                return false
        )
        return result

export default ApiClient
